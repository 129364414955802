import VisitorQuestionTmplCreatePage from '@/views/VisitorQuestionTmplCreate/index.vue'

export default {
  name: 'VisitorQuestionTmplPage',
  components: {
    VisitorQuestionTmplCreatePage,
  },
  data() {
    return {
      // 问题模板列表数据
      issueTemplateList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 创建弹窗
      drawerVisible: false,
      // 编辑数据
      editData: null,
      // 预览问题
      visitorQuestion: null,
      visitorQuestionVisible: false
    }
  },
  mounted() {
    this.getIssueTemplateList()

  },
  methods: {
    // 前往访客问题管理页面
    goToIssueMgtPage() {
      this.$router.push('/visitor_question_management')
    },
    // 获取问题模板列表
    getIssueTemplateList(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/v2/question/template/list', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
        type: this.reasonType
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.issueTemplateList = response.data.content.list;
          if (Array.isArray(this.issueTemplateList) && !this.issueTemplateList.length && this.pagesObj.page > 1) {
            this.getIssueTemplateList(this.pagesObj.page - 1)
            return
          }
          this.pagesObj = {
            ...this.pagesObj,
            total: response.data.content.page.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 删除
    deleteClick(row) {
      this.$utils.blurFn()
      this.$confirm(this.$t('securityRules.confirmDelete'), this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true
      }).then(() => {

        const requsetJson = {
          hrid: this.$store.getters.loginid,
          tnum: row.tnum
        }
        this.$http.post('/v2/question/template/delete', requsetJson).then(response => {
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: this.$t('visitorQuestionTmpl.deleteSuccess')
            });
            // 更新组列表
            this.getIssueTemplateList(this.pagesObj.page);
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      }).catch(() => {

      });
    },
    // 创建
    createAuthority() {
      this.editData = null
      this.drawerVisible = true
    },
    // 创建或修改成功
    createSuccess() {
      this.drawerVisible = false
      this.getIssueTemplateList()
    },
    // 关闭弹窗
    closeDrawer() {
      this.drawerVisible = false
    },
    // 修改
    editClick(item) {
      this.editData = item
      this.drawerVisible = true

    },
    // 搜索
    searchEvent() {
      this.getIssueTemplateList()
    },
    // 显示访客问题详情
    showQuestionDetail(data) {
      this.visitorQuestion = data
      this.visitorQuestionVisible = true
    },
    // 获取单选的选中项
    getSCChecked(item) {
      if (item.selected) return item.seq
    }

  }
}