<template>
  <div>
    <el-container class="container">
      <el-main class="main-wrapper">
        <div ref="tableWrapper">
          <el-table ref="tableRef" :data="questionList" style="width: 100%" @select="selectionChange">
            <el-table-column prop="description" :label="$t('visitorQuestionTmpl.visitorQuestionTopic')"> </el-table-column>

            <el-table-column prop="type" :formatter="getTypeDesc" :label="$t('visitorQuestionTmpl.visitorQuestionTopicType')"> </el-table-column>

            <el-table-column :label="$t('visitorQuestionTmpl.visitorQuestionRequired')" width="120">
              <template slot-scope="scope">
                <el-checkbox @change="questionRequireChange(scope.row)" v-model="scope.row.require" :disabled="scope.row.disabled"> </el-checkbox>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
            <div slot="empty">
              <el-empty :description="$t('visitorQuestionTmpl.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
            </div>
          </el-table>
          <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getQuestionList"></pagination-box>
        </div>
      </el-main>
      <el-footer class="footer-wrapper" height="auto">
        <el-button @click="closeDialog">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="visitorQuestionListConfirm">{{ $t('common.save') }}</el-button>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
