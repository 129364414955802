import VisitorQuestionCreatePage from '@/views/VisitorQuestionCreate/index.vue'

export default {
  name: 'VisitorQuestionPage',
  components: {
    VisitorQuestionCreatePage,
  },
  data() {
    return {
      // 问题列表数据
      issueList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 创建弹窗
      drawerVisible: false,
      // 编辑数据
      editData: null,
    }
  },
  mounted() {
    this.getIssueList()

  },
  computed: {
    // 问题类型列表
    issueTypeList() {
      return [
        {
          value: 'SC',
          label: this.$t('visitorQuestion.visitorQuestionTypeSc'),
        },
        {
          value: 'MC',
          label: this.$t('visitorQuestion.visitorQuestionTypeMc'),
        },
        {
          value: 'IN',
          label: this.$t('visitorQuestion.visitorQuestionTypeIn'),
        }
      ]
    },
  },
  methods: {
    backPage() {
      this.$router.back()
    },
    // 获取类型描述
    getTypeDesc(row) {
      let currentType = this.issueTypeList.find(iitem => iitem.value === row.type)
      return currentType.label
    },
    // 获取问题列表
    getIssueList(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/v2/question/list', {
        hrid: this.$store.getters.loginid,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.issueList = response.data.content.list;
          if (Array.isArray(this.issueList) && !this.issueList.length && this.pagesObj.page > 1) {
            this.getIssueList(this.pagesObj.page - 1)
            return
          }
          this.pagesObj = {
            ...this.pagesObj,
            total: response.data.content.page.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 删除
    deleteClick(row) {
      console.log('row', row);
      
      this.$utils.blurFn()
      this.$confirm(this.$t('securityRules.confirmDelete'), this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true
      }).then(() => {

        const requsetJson = {
          hrid: this.$store.getters.loginid,
          qnum: row.qnum
        }
        this.$http.post('/v2/question/delete', requsetJson).then(response => {
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: this.$t('visitorQuestion.deleteSuccess')
            });
            // 更新组列表
            this.getIssueList(this.pagesObj.page);
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      }).catch(() => {

      });
    },
    // 创建
    createAuthority() {
      this.editData = null
      this.drawerVisible = true
    },
    // 创建或修改成功
    createSuccess(isKeep) {
      this.drawerVisible = false
      // 如果是编辑的话，保持当前页
      this.getIssueList(isKeep ? this.pagesObj.page : '')
    },
    // 关闭弹窗
    closeDrawer() {
      this.drawerVisible = false
    },
    // 修改
    editClick(item) {
      this.editData = item
      this.drawerVisible = true

    },
    // 搜索
    searchEvent() {
      this.getIssueList()
    }

  }
}