<template>
  <web-layout :title="$t('visitorQuestionTmpl.visitorQuestionTmplTitle')">
    <template>
      <el-row :gutter="20" class="auth-header">
        <el-col :span="10">
          <el-input :placeholder="$t('visitorQuestionTmpl.visitorQuestionTmplSearchTips')" :title="query ? '' : $t('visitorQuestionTmpl.visitorQuestionTmplSearchTips')" v-model="query" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="13" :offset="1" style="text-align: right">
          <el-button @click="goToIssueMgtPage" type="primary">{{ $t('visitorQuestion.visitorQuestionBankTitle') }}</el-button>
          <el-button v-if="$store.getters.isOperate && !$store.getters.isDemo" @click="createAuthority" type="primary" icon="el-icon-add-solid">{{ $t('common.create') }}</el-button>
        </el-col>
      </el-row>
      <div ref="tableWrapper">
        <el-table ref="tableRef" :data="issueTemplateList" style="width: 100%">
          <el-table-column prop="name" :label="$t('visitorQuestionTmpl.visitorQuestionTmplName')"> </el-table-column>

          <el-table-column prop="description" :label="$t('visitorQuestionTmpl.visitorQuestionTmplDesc')"> </el-table-column>
          <el-table-column :label="$t('common.operation')" width="120">
            <template slot-scope="scope">
              <el-tooltip effect="light" :content="$t('common.preview')" placement="top">
                <el-button @click="showQuestionDetail(scope.row)" type="text" icon="el-icon-preview"></el-button>
              </el-tooltip>
              <template v-if="$store.getters.isOperate && !$store.getters.isDemo">
                <el-tooltip effect="light" :content="$t('common.edit')" placement="top">
                  <el-button @click="editClick(scope.row)" type="text" icon="el-icon-edit"></el-button>
                </el-tooltip>
                <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                  <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
                </el-tooltip>
              </template>
            </template>
          </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('visitorQuestionTmpl.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getIssueTemplateList"></pagination-box>
      </div>
    </template>

    <!-- 问题表单详情弹窗 -->
    <el-dialog v-if="visitorQuestionVisible" :visible.sync="visitorQuestionVisible" width="800px" :title="visitorQuestion.name" top="25px">
      <!-- 问题表单 -->
      <el-form class="question_form preview_question_form" ref="questionFormRef" label-width="180px" label-position="top">
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionTmplDesc')">
          <el-input disabled type="textarea" :rows="4" v-model="visitorQuestion.description"> </el-input>
        </el-form-item>
        <el-form-item class="question_item" :required="qItem.require" v-for="qItem in visitorQuestion.questions" :key="qItem.qnum" :label="qItem.seq + '、' + qItem.question.description">
          <div class="content_box">
            <!-- 单选 -->
            <template v-if="qItem.question.type === 'SC'">
              <!-- 选项 -->
              <el-radio v-for="bItem in qItem.question.body" :key="bItem.seq" :label="bItem.seq" disabled :value="getSCChecked(bItem)">
                {{ bItem.description }}
              </el-radio>
            </template>
            <!-- 多选 -->
            <template v-if="qItem.question.type === 'MC'">
              <el-checkbox v-for="bItem in qItem.question.body" :label="bItem.seq" :key="bItem.seq" :checked="bItem.selected" disabled>
                {{ bItem.description }}
              </el-checkbox>
            </template>
            <!-- 填空 -->
            <template v-if="qItem.question.type === 'IN'">
              <el-input type="text" :title="qItem.question.answer" v-model="qItem.question.answer" v-if="qItem.question.inputtype === 'TXT'" readonly> </el-input>
              <el-input type="number" v-model="qItem.question.answer" v-if="qItem.question.inputtype === 'NUM'" readonly> </el-input>
              <el-date-picker v-if="qItem.question.inputtype === 'DATE'" v-model="qItem.question.answer" type="datetime" readonly> </el-date-picker>
            </template>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 新增修改 -->
    <el-drawer append-to-body :title="editData ? $t('visitorQuestionTmpl.visitorQuestionTmplEdit') : $t('visitorQuestionTmpl.visitorQuestionTmplAdd')" custom-class="drawer-wrapper" :destroy-on-close="true" :visible.sync="drawerVisible">
      <visitor-question-tmpl-create-page v-if="drawerVisible" :editData="editData" @closeDrawer="closeDrawer" @createSuccess="createSuccess"></visitor-question-tmpl-create-page>
    </el-drawer>
  </web-layout>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
