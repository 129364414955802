<template>
  <web-layout>
    <template v-slot:title>
      <el-button icon="el-icon-back" @click="backPage" size="medium" style="vertical-align: middle; margin-right: 10px">{{ $t('common.back') }}</el-button>
      <span style="vertical-align: middle">{{ $t('visitorQuestion.visitorQuestionBankTitle') }}</span>
    </template>
    <template>
      <el-row :gutter="20" class="auth-header">
        <el-col :span="10">
          <el-input :placeholder="$t('visitorQuestion.searchTips')" :title="query ? '' : $t('visitorQuestion.searchTips')" v-model="query" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="13" :offset="1" style="text-align: right">
          <el-button v-if="$store.getters.isOperate && !$store.getters.isDemo" @click="createAuthority" type="primary" icon="el-icon-add-solid">{{ $t('common.create') }}</el-button>
        </el-col>
      </el-row>
      <div ref="tableWrapper">
        <el-table ref="tableRef" :data="issueList" style="width: 100%">
          <el-table-column prop="description" :label="$t('visitorQuestionTmpl.visitorQuestionTopic')"> </el-table-column>

          <el-table-column prop="type" :formatter="getTypeDesc" :label="$t('visitorQuestionTmpl.visitorQuestionTopicType')"> </el-table-column>
          <el-table-column :label="$t('common.operation')" width="120">
            <template slot-scope="scope" v-if="$store.getters.isOperate && !$store.getters.isDemo">
              <el-tooltip effect="light" :content="$t('common.edit')" placement="top">
                <el-button @click="editClick(scope.row)" type="text" icon="el-icon-edit"></el-button>
              </el-tooltip>
              <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('visitorQuestion.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getIssueList"></pagination-box>
      </div>
    </template>

    <!-- 新增修改 -->
    <el-dialog append-to-body :title="editData ? $t('visitorQuestion.visitorQuestionEdit') : $t('visitorQuestion.visitorQuestionAdd')" :visible.sync="drawerVisible" destroy-on-close width="650px">
      <visitor-question-create-page v-if="drawerVisible" :editData="editData" @createSuccess="createSuccess" @closeDialog="closeDrawer"></visitor-question-create-page>
    </el-dialog>
  </web-layout>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
