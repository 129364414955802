
export default {
	name: 'VisitorQuestionTmplSelectQuestionPage',
	props: {
		selectedData: {
			type: Array,
			default: function () {
				return []
			}
		}
	},
	data() {
		return {
			// 选择问题列表
			questionList: [],
			// 翻页配置
			pagesObj: {
				page: 1,
				pagesize: 5,
				total: 0
			},
			checkedList: [], // 选中的问题项
		}
	},
	created() {
		this.$nextTick(() => {
			this.getQuestionList()
		})
	},
	computed: {
		// 问题类型列表
		issueTypeList() {
			return [
				{
					value: 'SC',
					label: this.$t('visitorQuestion.visitorQuestionTypeSc'),
				},
				{
					value: 'MC',
					label: this.$t('visitorQuestion.visitorQuestionTypeMc'),
				},
				{
					value: 'IN',
					label: this.$t('visitorQuestion.visitorQuestionTypeIn'),
				}
			]
		},
	},
	methods: {
		// 获取类型描述
		getTypeDesc(row) {
			let currentType = this.issueTypeList.find(iitem => iitem.value === row.type)
			return currentType.label
		},
		// 获取问题列表
		getQuestionList(page) {
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http.post('/v2/question/list', {
				hrid: this.$store.getters.loginid,
				"query": '',
				page: this.pagesObj.page,
				pagesize: this.pagesObj.pagesize,
			}, {
				loadTarget: this.$refs.tableWrapper
			}).then(response => {
				let { content } = response.data
				// 更新数据
				if (response.data.success) {
					content.list.forEach(lItem => {
                        lItem.checked = false
                        lItem.require = false
                    })
					this.questionList = content.list;

					this.pagesObj = {
						...this.pagesObj,
						total: response.data.content.page.total
					}

					// 回显选中的项
					this.questionList.forEach(lItem => {
						let isQuestionListExist = this.selectedData.some(iItem => iItem.qnum === lItem.qnum)
						lItem.disabled = isQuestionListExist
						let matchItem = this.checkedList.find(cItem => lItem.qnum === cItem.qnum)
						if (matchItem) {
							lItem.checked = true
							lItem.require = matchItem.require
						}
					})

					this.$nextTick(() => {
						this.checkedList.forEach(element => {
							let selectRow = this.questionList.find((item) => item.qnum == element.qnum)
							if (selectRow) {
								this.$refs.tableRef.toggleRowSelection(selectRow, true)
							}

						})
					})

				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 问题是否必填勾选变化
		questionRequireChange(item) {
			let matchItem = this.checkedList.find(cItem => cItem.qnum === item.qnum)
			// 如果在选中列表中存在
			if (matchItem) {
				// 修改选项列表中对应项
				matchItem.require = item.require
			} else {
				// 不存在，选中列表中添加一项
				item.checked = true
				this.$refs.tableRef.toggleRowSelection(item);
				this.checkedList.push(item)
			}
		},
		// 列表选择项
		selectionChange(data, row) {
			let isCancelSelect = false
			// 取消选项
			if (data.length == 0) {
				isCancelSelect = true

			} else {
				if (data[data.length - 1].qnum != row.qnum) {

					isCancelSelect = true
				}
			}

			if (isCancelSelect) {
				for (let index = 0; index < this.checkedList.length; index++) {
					if (this.checkedList[index].qnum == row.qnum) {
						this.checkedList.splice(index, 1)
					}

				}
			} else {
				this.checkedList.push(data[data.length - 1])
			}
			// this.selectedData = data
		},
		// 选择问题确认
		visitorQuestionListConfirm() {
			this.$emit('comfirmSelect', this.checkedList)
			// // 拼接
			// this.templateForm.issueList = [...this.templateForm.issueList, ...qList]
			// console.log('this.templateForm.issueList', this.templateForm.issueList);

			// this.getPaginationQuestionList(1)
			// this.visitorQuestionListClose()
		},
		// 关闭
		closeDialog() {
			this.$emit('closeDialog')
		},
	}
}